import React, { Component } from 'react';
import './login.css';
import { Image } from 'react-bootstrap';
import api from '../services/api';
import Alert from '../components/Alert/Alert';
import { CircularProgress } from '@mui/material';
import { login } from '../functions/functions';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      useremail: '',
      password: '',
      showAlert: false,
      alertMessage: '',
      isLoading: false
    };
  }

  componentDidMount() {
    localStorage.clear();
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    try {
      const response = await login(this.state.useremail, this.state.password);
      //console.log(response)
      if (response?.token) {
        localStorage.setItem('token', response.token);
        setTimeout(() => {
          window.location.href = '/home';
        }, 1000);
      } else {
        this.setState({ alertMessage: `Falha no login: ${response.message} - ${response.status}`, showAlert: true, isLoading: false });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      if (error.response) {
        // O servidor respondeu com um status diferente de 2xx
        const statusCode = error.response.status;
        const errorMsg = error.response.data.message || 'An error occurred';
        //console.error(`Falha no login:  ${errorMsg} - ${statusCode}`);
        this.setState({ alertMessage: `Falha no login: ${errorMsg} - ${statusCode}`, showAlert: true });
      } else if (error.request) {
        // A requisição foi feita, mas nenhuma resposta foi recebida
        //console.error('No response received:', error.request);
        this.setState({ alertMessage: 'Nenhuma resposta do servidor', showAlert: true });
      } else {
        // Algo aconteceu ao configurar a requisição que acionou um erro
        //console.error('Error setting up request:', error.message);
        this.setState({ alertMessage: `Falha no login: ${error.message}`, showAlert: true });
      }
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  handleChangeNumber = (event) => {
    const { name, value } = event.target;
    if (/^\d*$/.test(value)) {
      this.setState({
        [name]: value
      });
    }
  };

  handleCloseAlert = () => {
    this.setState({ showAlert: false });
  };

  render() {
    return (
      <section className="vh-100 vw-100 login">
        <div className='h-100 w-100 d-flex'>
          <div className="login-usuario select-none p-4 m-auto">
            <div className="box">
              <form onSubmit={this.handleSubmit}>
                <div className="title">
                  <h1>{process.env.REACT_APP_NAME}</h1>
                </div>
                <div className="input-box">
                  <label htmlFor="username" className="label-color"> E-mail</label>
                  <input
                    id="useremail"
                    name="useremail"
                    type="text"
                    placeholder="e-mail"
                    value={this.state.useremail}
                    onChange={(event) => this.handleChange(event)}
                    required
                    autoComplete="username"
                  />
                  <br />
                  <label htmlFor="password" className="label-color"> Senha</label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="senha"
                    value={this.state.password}
                    onChange={(event) => this.handleChangeNumber(event)}
                    maxLength={6}
                    required
                    autoComplete="current-password"
                  />
                  <br />

                  <button type="submit" className={"Login"}>
                    {this.state.isLoading ? <CircularProgress size={24} /> : 'Login'}
                  </button>

                  {/*   <input type="submit" className="Login" value="Login" /> */}
                  <br />
                  {/* <p className="link-text">Forget password ? <a href="#">Click Here</a></p>
            <p className="link-text">
              Don't have an account?
              <a href="#">Click Here</a>
            </p> */}
                </div>
              </form>
              {this.state.showAlert && <Alert message={this.state.alertMessage} onClose={() => this.handleCloseAlert()} />} {/* Renderize o alerta */}
            </div>
          </div>
        </div>
      </section >
    );
  }
}

export default LoginForm;
