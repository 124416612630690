import React, { useState } from 'react';
import {
  Button,
  Paper,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Funcoes from '../../functions/functions';
import * as Formatar from '../../functions/Format';

const ConsultarConta = ({ user, handleSnackbar, loading, setLoading }) => {
  const [cpf, setCpf] = useState('');
  const [userDetails, setUserDetails] = useState(null);
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');

  const handleCpfInput = (value) => {
    const numericValue = value.replace(/\D/g, '');
    const formattedValue = numericValue
      .replace(/^(\d{3})(\d)/, '$1.$2')
      .replace(/^(\d{3}\.\d{3})(\d)/, '$1.$2')
      .replace(/^(\d{3}\.\d{3}\.\d{3})(\d{1,2})$/, '$1-$2')
      .slice(0, 14);
    setCpf(formattedValue);
  };

  const consultarConta = async () => {
    setLoading(true);
    try {
      const searchFormatted = cpf.replace(/\D/g, ''); // Remove a máscara do CPF
      const response = await Funcoes.getUserDetailDoc(user.id, searchFormatted, '');
      //console.log(response);
      setUserDetails(response.data); // Dados do usuário

      // Preenche os campos de email e telefone com os dados recebidos
      setEmail(response.data.email || '');
      setTelefone(response.data.userData?.telephone || '');

      handleSnackbar('Conta consultada com sucesso!', 'success');
    } catch (error) {
      handleSnackbar('Erro ao consultar conta.', 'error');
      setUserDetails(null);
    } finally {
      setLoading(false);
    }
  };

  const verificarConta = (bank) => {
    return userDetails?.accountData.some((account) => account.chosenbank === bank);
  };

  const handleCreateAccount = async (bank) => {
    try {
      if (!email) {
        handleSnackbar('Por favor, preencha um email válido.', 'error');
        return;
      }

      if (!telefone) {
        handleSnackbar('Por favor, preencha um telefone válido.', 'error');
        return;
      }

      setLoading(true);

      const accountData = {
        managerId: userDetails.accountData[0]?.managerId,
        operatorid: userDetails.accountData[0]?.operatorid || null,
        profile: userDetails.accountData[0]?.profile,
      };

      // Chamada para criar a conta no banco específico
      const response = await Funcoes.createAccount({
        userId: userDetails.id,
        chosenbank: bank,
        email,
        telefone,
        ...accountData,
      });
      if (response.success) {
        handleSnackbar(`Conta ${bank.toUpperCase()} criada com sucesso!`, 'success');

        // Atualizar os detalhes do usuário com a nova conta
        const updatedUserDetails = { ...userDetails };
        updatedUserDetails.accountData.push(response.data);
        setUserDetails(updatedUserDetails);
      } else {
        const msg = response.message == '"Account already exists"' ? `Já existe a conta na LQ ${bank.toUpperCase()}` : `Erro ao criar conta ${bank.toUpperCase()}`;
        handleSnackbar(`${msg}!`, 'error');
      }


    } catch (error) {
      console.error('Erro ao criar conta:', error);
      handleSnackbar(`Erro ao criar conta ${bank.toUpperCase()}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const renderAccountDetails = (account) => (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }} key={account.id}>
      <Typography variant="subtitle1"><strong>Status da Conta:</strong> {account.status == 1 ? "ATIVA" : "BLOQUEADA"}</Typography>
      <Typography variant="subtitle1"><strong>Banco:</strong> {account.chosenbank.toUpperCase()}</Typography>
      <Typography variant="subtitle1"><strong>Agência:</strong> {`${account.agency} - Dígito: ${account.digitagency}`}</Typography>
      <Typography variant="subtitle1"><strong>Conta:</strong> {`${account.account} - Dígito: ${account.digitaccount}`}</Typography>
      <Typography variant="subtitle1"><strong>Conta Pagamento:</strong> {account.paymentaccount}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
          <strong>Link KYC:</strong> {account.link}
        </Typography>
        {/*  {account.link && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            const whatsappMessage = `Olá, segue o link KYC da sua conta no banco ${account.chosenbank.toUpperCase()}: ${account.link}`;
            const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(whatsappMessage)}`;
            window.open(whatsappUrl, '_blank');
          }}
          sx={{ ml: 2 }}
        >
          Enviar via WhatsApp
        </Button>
      )} */}
      </Box>
    </Paper>
  );

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Consultar Conta</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="CPF"
              value={cpf}
              onChange={(e) => handleCpfInput(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={consultarConta}
              disabled={loading || cpf.length !== 14}
              startIcon={loading && <CircularProgress size={20} />}
            >
              {loading ? 'Consultando...' : 'Consultar'}
            </Button>
          </Grid>
        </Grid>

        {userDetails && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">Informações do Usuário</Typography>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
              <Typography><strong>Nome:</strong> {userDetails.userData?.name}</Typography>
              <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                sx={{ mt: 2 }}
              />
              <TextField
                label="Telefone"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
                fullWidth
                sx={{ mt: 2 }}
              />
              <Typography><strong>Documento:</strong> {userDetails.userData?.document}</Typography>
              <Typography><strong>Endereço:</strong> {`${userDetails.addressData?.address}, ${userDetails.addressData?.number}, ${userDetails.addressData?.district}, ${userDetails.addressData?.city} - ${userDetails.addressData?.state}`}</Typography>
            </Paper>

            <Typography variant="h6">Contas Associadas</Typography>
            {userDetails.accountData.map(renderAccountDetails)}

            {!verificarConta('qesh') && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleCreateAccount('qesh')}
                sx={{ mt: 2, mb: 2 }}
              >
                Criar Conta QESH
              </Button>
            )}

            {!verificarConta('bmb') && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleCreateAccount('bmb')}
                sx={{ mt: 2, mb: 2 }}
              >
                Criar Conta BMB
              </Button>
            )}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default ConsultarConta;
