import React, { useState, useEffect } from 'react';
import { fetchContas } from './utils/apiCalls';
import DataTable from './DataTable';
import Loading from './Loading';
import {
  Box,
  Container,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Button
} from '@mui/material';
import * as Funcoes from '../../functions/functions';

const Contas = ({ user, checkAuthentication }) => {
  const getInitialState = () => ({
    user: JSON.parse(localStorage.getItem('user')),
    alertMessage: '',
    page: 0,
    rowsPerPage: 25, // Configuração padrão de 10 registros por página
    orderBy: 'id', // Coluna padrão para ordenação
    order: 'desc', // Ordem ascendente
    show: false,
    loadTela: true,
    tabelaIni: [],
    totalItems: 0,
    tabelaFiltro: [],
    mostrar_contas: true,
    search: '',
    dadosAcao: [],
    onlyManagerAccounts: false,
    chosenbank: localStorage.getItem('chosenbank'), // Banco selecionado salvo no localStorage
    chosenbankSet: localStorage.getItem('chosenbank'), // Banco selecionado salvo no localStorage
  });

  const [state, setState] = useState(getInitialState());


  useEffect(() => {
    async function fetchDatacheck() {
      await checkAuthentication();
    }
    fetchDatacheck();
  }, []);

  useEffect(() => {
    setState(prevState => ({ ...prevState, user: JSON.parse(localStorage.getItem('user')) }));
  }, []);

  useEffect(() => {
    fetchData();
  }, [state.onlyManagerAccounts, state.chosenbank]); // Atualiza ao mudar a escolha do banco

  const fetchcheckAuthentication = async () => {
    await checkAuthentication();
  };

  const fetchData = async (newPage = state.page, newRowsPerPage = state.rowsPerPage, sortField = state.orderBy, sortOrder = state.order, onlyManagerAccounts = state.onlyManagerAccounts, chosenbank = state.chosenbank) => {
    const { user } = state;

    if (sortField === "configData.balance") sortField = "balance";
    if (sortField === "id") sortField = "user_createdAt";
    if (sortField === "userData.name") sortField = "userdata_name";
    if (sortField === "userData.document") sortField = "userdata_document";
    if (sortField === "ag") sortField = "account_account";
    

    try {
      const users = await fetchContas(user.id, newPage + 1, newRowsPerPage, sortField, sortOrder, onlyManagerAccounts, chosenbank);
      //console.log(users)
      setTabelaFiltro(users.data);
      setTabelaIni(users.data);
      setLoadTela(false);
      setTotalItems(users.totalItems);
    } catch (error) {
      setLoadTela(false);
    }
  };

  const pesquisar = async (search) => {
    const searchFormatted = search.replace(/\D/g, "");

    const dadosFiltrados = state.tabelaIni.filter((item) => item.userData.document.includes(searchFormatted));

    if (dadosFiltrados.length > 0) {
      setTabelaFiltro(dadosFiltrados);
      setState(prevState => ({ ...prevState }));
    } else if (searchFormatted.length === 11 || searchFormatted.length === 14) {
      const managerId = state.user.id;
      try {
        const response = await Funcoes.getUserDetailDoc(managerId, searchFormatted, state.chosenbank);
        //console.log(response);
        if (response && response.data) {
          const newList = [...state.tabelaIni, response.data];
          setTabelaFiltro([response.data]);
          setState(prevState => ({ ...prevState }));
        }
      } catch (error) {
        // Tratar erro se necessário
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData(newPage, state.rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    fetchData(0, newRowsPerPage);
  };

  const handleRequestSort = (property) => {
    const isAsc = state.orderBy === property && state.order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrderBy(property);
    setOrder(newOrder);
    fetchData(state.page, state.rowsPerPage, property, newOrder);
  };

  const handleBankSelection = (bank) => {
    setState(prevState => ({ ...prevState, chosenbank: bank, chosenbankSet: bank }));
  };

  // Funções para atualizar estado separadamente
  const setTabelaFiltro = (data) => setState(prevState => ({ ...prevState, tabelaFiltro: data }));
  const setTabelaIni = (data) => setState(prevState => ({ ...prevState, tabelaIni: data }));
  const setLoadTela = (load) => setState(prevState => ({ ...prevState, loadTela: load }));
  const setTotalItems = (total) => setState(prevState => ({ ...prevState, totalItems: total }));
  const setPage = (page) => setState(prevState => ({ ...prevState, page }));
  const setRowsPerPage = (rowsPerPage) => setState(prevState => ({ ...prevState, rowsPerPage }));
  const setOrderBy = (orderBy) => setState(prevState => ({ ...prevState, orderBy }));
  const setOrder = (order) => setState(prevState => ({ ...prevState, order }));

  const { loadTela, mostrar_contas, tabelaFiltro, order, orderBy, rowsPerPage, page, totalItems } = state;

  if (loadTela) {
    return <Loading />;
  } else {
    return (
      <Container maxWidth="xl" sx={{ mt: 14, mb: 4 }}>
        <Grid container spacing={2} direction="column" alignItems="flex-start">
          {/* Campo de pesquisa e checkbox */}
          <Grid item xs={12} display="flex" justifyContent="flex-start" alignItems="center" sx={{ mt: 2 }}>
            <TextField
              size="small"
              variant="outlined"
              label="Pesquisar por CPF"
              value={state.search}
              onChange={(texto) => {
                setState(prevState => ({ ...prevState, search: texto.target.value }));
                pesquisar(texto.target.value);
              }}
              sx={{
                mr: 2,
                minWidth: '200px',
                '& .MuiOutlinedInput-root': { // Aplica estilos diretamente no campo de entrada
                  borderRadius: 5,
                },
              }}
            />

            {state.user.accountData[0]?.profile === 'master' && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.onlyManagerAccounts}
                    onChange={(event) => {
                      setState(prevState => ({ ...prevState, onlyManagerAccounts: event.target.checked }));
                    }}
                    name="onlyManagerAccounts"
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body1" color="textPrimary">
                    Somente Contas Gerente
                  </Typography>
                }
              />
            )}
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="flex-start" alignItems="center" sx={{ mt: 2 }}>
            <Typography variant="body1" color="#FFFFFF">
              {state.chosenbank === 'bmb' ? "Banco BMB Ag: 0001" : "Banco QESH Ag: 0001"}
            </Typography>
          </Grid>

          {/* Tabela de contas */}
          {mostrar_contas && (
            <Grid item xs={12} sx={{  width: '100%' }} display="flex" justifyContent="flex-start">
              <DataTable
                onChange={() => { }}
                state={state}
                setState={setState}
                fetchcheckAuthentication={fetchcheckAuthentication}
                user={user}
                data={tabelaFiltro}
                order={order}
                orderBy={orderBy}
                rowsPerPage={rowsPerPage}
                page={page}
                count={totalItems}
                onSort={handleRequestSort}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onRowClick={(row) =>
                  setState(prevState => ({
                    ...prevState,
                    dadosAcao: row,
                    mostrar_contas: false,
                    mostrar_acao: true,
                  }))
                }
              />
            </Grid>
          )}
        </Grid>
      </Container>

    );
  }
};

export default Contas;
