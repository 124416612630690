import React from 'react';
import { Container, Grid, CircularProgress } from '@mui/material';

const Loading = () => (
  <Container maxWidth="xl" sx={{ mt: 14, mb: 4 }}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CircularProgress size={30} />
      </Grid>
    </Grid>
  </Container>
);

export default Loading;
