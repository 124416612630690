import React, { useState, useEffect } from 'react';
import './tarifa.css';
import * as Formatar from '../../functions/Format';
import { getTariffs, validateToken, cadastrarTarifaPadrao, alterarTarifaPadrao } from '../../functions/functions';
import {
  Box,
  Button,
  Container,
  Paper,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  TextField,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Modal,
  Snackbar,
  Alert,
  InputLabel
} from '@mui/material';
import { ArrowDownward as ArrowDownwardIcon, Add } from '@mui/icons-material';

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const Tarifa = ({ user, tariff, checkAuthentication }) => {
  const [state, setState] = useState({
    user: JSON.parse(localStorage.getItem('user')),
    tariff: Array.isArray(tariff) ? tariff : JSON.parse(localStorage.getItem('tariff')),
    page: 0,
    rowsPerPage: 50,
    orderBy: '',
    order: 'asc',
    columns: [
      { id: 'id', label: 'ID' },
      { id: 'data_criacao', label: 'Data' },
      { id: 'chaveTarifa', label: 'Chave Tarifa' },
      { id: 'descricao', label: 'Descrição' },
      { id: 'valor', label: 'Valor' },
      { id: 'tipo_de_cobranca', label: 'Tipo de Cobrança' }
    ],
    mostrar_editar_tarifa_padrao: false,
    mostrar_cadastro_tarifa_padrao: false,
    tabelaIni: [],
    tabelaFiltro: [],
    dadosAcao: [],
    loadCadastrarTarifaPadrao: false,
    loadAlterarTarifaPadrao: false,
    chaveTarifa: '',
    descricao: '',
    valor: '',
    tipo_de_cobranca: 0,
    // Estados para controlar o Snackbar
    snackbarOpen: false,
    snackbarMessage: '',
    snackbarSeverity: 'success'
  });

  useEffect(() => {
    async function fetchDatacheck() {
      await checkAuthentication();
    }
    fetchDatacheck();
  }, []);

  // useEffect separado para garantir que o `user` esteja carregado antes de prosseguir
  useEffect(() => {
    if (user && Object.keys(user).length > 0) { // Certifica-se que o user está disponível e carregado
      setState(prevState => ({
        ...prevState,
        user: user,
        tariff: Array.isArray(tariff) ? tariff : [],
      }));
      fetchTariffs();
    }
  }, [user]); // Executa sempre que `user` mudar

  const fetchTariffs = async () => {
    try {
      // Busca as tarifas
      let page = 1;
      let pageSize = 50;
      let profile = state?.user?.accountData[0]?.profile || 0;
      let id = state?.user?.id || 0;

      const tarifas = await getTariffs(page, pageSize, profile, id);

      // Filtra as tarifas onde originaccount não é nulo
      const tarifasFiltradas = tarifas.filter(tarifa => tarifa.originaccount === null);

      // Atualiza o estado com as tarifas filtradas
      setState(prevState => ({
        ...prevState,
        tabelaIni: tarifasFiltradas,  // Define a tabela inicial com as tarifas filtradas
        tabelaFiltro: tarifasFiltradas  // Aplica o mesmo filtro na tabela de filtro, se for necessário
      }));
    } catch (error) {
      //console.error('Erro ao buscar tarifas:', error);
      openSnackbar('Erro ao buscar tarifas', 'error');
    }
  };


  const openSnackbar = (message, severity) => {
    setState(prevState => ({
      ...prevState,
      snackbarMessage: message,
      snackbarSeverity: severity,
      snackbarOpen: true
    }));
  };

  const handleSnackbarClose = () => {
    setState(prevState => ({
      ...prevState,
      snackbarOpen: false
    }));
  };

  const cadastrarTarifa = async () => {
    setState(prevState => ({ ...prevState, loadCadastrarTarifaPadrao: true }));
    const { chaveTarifa, descricao, valor, tipo_de_cobranca } = state;
    if (!chaveTarifa || !descricao || !valor || !tipo_de_cobranca) {
      openSnackbar('Preencha todos os campos', 'warning');
      setState(prevState => ({ ...prevState, loadCadastrarTarifaPadrao: false }));
      return;
    }

    try {
      await cadastrarTarifaPadrao(chaveTarifa, descricao, valor, tipo_de_cobranca);
      openSnackbar('Tarifa cadastrada com sucesso', 'success');
      await fetchTariffs(); // Recarrega as tarifas após o cadastro
      setState(prevState => ({
        ...prevState,
        loadCadastrarTarifaPadrao: false,
        mostrar_cadastro_tarifa_padrao: false,
        chaveTarifa: '',
        descricao: '',
        valor: '',
        tipo_de_cobranca: 0
      }));
    } catch (error) {
      setState(prevState => ({ ...prevState, loadCadastrarTarifaPadrao: false, mostrar_cadastro_tarifa_padrao: false }));
      openSnackbar('Erro no cadastro da tarifa', 'error');
    }
  };

  const alterarTarifa = async () => {
    setState(prevState => ({ ...prevState, loadAlterarTarifaPadrao: true }));
    const { chaveTarifa, descricao, valor, tipo_de_cobranca, dadosAcao } = state;

    try {
      await alterarTarifaPadrao(chaveTarifa, descricao, valor, tipo_de_cobranca, dadosAcao);
      openSnackbar('Tarifa alterada com sucesso', 'success');
      await fetchTariffs(); // Recarrega as tarifas após a alteração
      setState(prevState => ({
        ...prevState,
        loadAlterarTarifaPadrao: false,
        mostrar_editar_tarifa_padrao: false,
        chaveTarifa: '',
        descricao: '',
        valor: '',
        tipo_de_cobranca: 0
      }));
    } catch (error) {
      setState(prevState => ({ ...prevState, loadAlterarTarifaPadrao: false, mostrar_editar_tarifa_padrao: false }));
      openSnackbar('Erro na alteração da tarifa', 'error');
    }
  };

  const handleChangePage = (event, newPage) => {
    setState(prevState => ({ ...prevState, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    setState(prevState => ({ ...prevState, rowsPerPage: parseInt(event.target.value, 10), page: 0 }));
  };

  const handleRequestSort = (property) => {
    const isAsc = state.orderBy === property && state.order === 'asc';
    setState(prevState => ({ ...prevState, orderBy: property, order: isAsc ? 'desc' : 'asc' }));
  };

  const fecharModalTarifaPadrao = () => {
    setState(prev => ({
      ...prev,
      chaveTarifa: '',
      descricao: '',
      valor: '',
      tipo_de_cobranca: 0, // Define um valor padrão
      mostrar_editar_tarifa_padrao: false // Fecha o modal
    }));
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9.,]*$/; // Permitir apenas números, pontos e vírgulas

    if (regex.test(inputValue)) {
      setState(prevState => ({ ...prevState, valor: inputValue }));
    }
  };

  let {
    chaveTarifa,
    descricao,
    valor,
    tipo_de_cobranca,
    mostrar_cadastro_tarifa_padrao,
    mostrar_tarifa,
    tabelaFiltro,
    dadosAcao,
    mostrar_editar_tarifa_padrao,
    orderBy,
    order,
    rowsPerPage,
    page,
    columns,
  } = state;

  return (
    <Container maxWidth="xl" sx={{ mt: 14, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ flexGrow: 1, mb: 2, mt: 2 }}>
            <h2>{'Tarifas'}</h2>
          </Box>

          <Accordion sx={{ width: '100%' }}>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Tarifa Padrão</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={2} sm={1}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ height: '40px', marginTop: '14px', fontSize: '0.875rem' }}
                    color="success"
                    onClick={() =>
                      setState(prevState => ({ ...prevState, mostrar_cadastro_tarifa_padrao: true }))
                    }
                  >
                    {"Cadastrar"}
                  </Button>
                </Grid>
              </Grid>

              {mostrar_cadastro_tarifa_padrao && (
                <Box sx={{
                  flexGrow: 1,
                  borderWidth: 1,
                  borderColor: '#dddddd',
                  borderStyle: 'double',
                  padding: 2,
                  borderRadius: 5,
                  mb: 2
                }}>
                  <h2>{'Preencha os dados para criar a tarifa.'}</h2>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        label="Chave Tarifa"
                        value={chaveTarifa}
                        onChange={(event) => setState(prevState => ({ ...prevState, chaveTarifa: event.target.value }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        label="Descrição"
                        value={descricao}
                        onChange={(event) => setState(prevState => ({ ...prevState, descricao: event.target.value }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        label="Valor"
                        value={valor}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl size="small" variant="outlined" margin="normal" fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">Tipo de cobrança</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={tipo_de_cobranca}
                          onChange={(event) => setState(prevState => ({ ...prevState, tipo_de_cobranca: event.target.value }))}
                          label="Tipo de cobrança"
                        >
                          <MenuItem value="">
                            <em>Escolha</em>
                          </MenuItem>
                          <MenuItem value={1}>Dinheiro</MenuItem>
                          <MenuItem value={2}>Porcentagem</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: '16px'
                        }}
                      >
                        <Button
                          size="small"
                          variant="contained"
                          color='error'
                          sx={{
                            height: '40px',
                            fontSize: '0.875rem',
                            marginBottom: '5px',
                            position: 'relative'
                          }}
                          onClick={() => setState(prevState => ({ ...prevState, chaveTarifa: '', descricao: '', valor: '', tipo_de_cobranca: '', mostrar_cadastro_tarifa_padrao: false }))}
                        >
                          Voltar
                        </Button>

                        <Button
                          size="small"
                          variant="contained"
                          sx={{
                            height: '40px',
                            fontSize: '0.875rem',
                            marginBottom: '5px',
                            position: 'relative'
                          }}
                          color="success"
                          onClick={cadastrarTarifa}
                          disabled={state.loadCadastrarTarifaPadrao}
                        >
                          {state.loadCadastrarTarifaPadrao && (
                            <CircularProgress
                              size={24}
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px'
                              }}
                            />
                          )}
                          {state.loadCadastrarTarifaPadrao ? 'Carregando...' : 'Cadastrar'}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}

              {tabelaFiltro && tabelaFiltro.length > 0 && (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align="left"
                            sortDirection={orderBy === column.id ? order : false}
                          >
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : 'asc'}
                              onClick={() => handleRequestSort(column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                        <TableCell align="center">Ação</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stableSort(tabelaFiltro, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                          <TableRow
                            key={row.id}
                            className={index % 2 === 0 ? 'even-row' : 'odd-row'}
                          >
                            <TableCell align="left">{row.id}</TableCell>
                            <TableCell align="left">
                              {Formatar.formatarDateAnoHoraSegundo(row.createdAt)}
                            </TableCell>
                            <TableCell align="left">
                              {row.tariffkey}
                            </TableCell>
                            <TableCell align="left">{row.description}</TableCell>
                            <TableCell align="left">
                              {row.typeofcharge == 1
                                ? Formatar.converterCentavosParaReais(row?.value ? row.value : 0)
                                : Formatar.converterCentavosEmPorcentagem(row?.value ? row.value : 0) + '%'}
                            </TableCell>
                            <TableCell align="left">{row.typeofcharge == 1 ? 'Dinheiro' : 'Porcentagem'}</TableCell>

                            <TableCell align="center">
                              <Button
                                onClick={async () => {
                                  setState((prevState) => ({
                                    ...prevState,
                                    dadosAcao: row,
                                    descricao: row.description,
                                    valor: row.value,
                                    tipo_de_cobranca: row.typeofcharge == 1 ? 1 : 2,
                                    mostrar_editar_tarifa_padrao: true
                                  }));
                                }}
                              >
                                <Add />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>

                  <TablePagination
                    rowsPerPageOptions={[50, 100, 150]}
                    component="div"
                    count={tabelaFiltro.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{ textAlign: 'center', justifyContent: 'center', alignSelf: 'center' }}
                  />
                </TableContainer>
              )}

            </AccordionDetails>
          </Accordion>

          <Modal
            open={mostrar_editar_tarifa_padrao}
            onClose={() => fecharModalTarifaPadrao()}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...style }}>
              <h6 id="child-modal-title">{"Editar " + dadosAcao.description}</h6>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    label="Descrição"
                    value={descricao}
                    onChange={(event) => setState(prevState => ({ ...prevState, descricao: event.target.value }))}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    label={tipo_de_cobranca == 1 ? "Valor R$" : " Valor %"}
                    value={valor}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl size="small" variant="outlined" margin="normal" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Tipo de cobrança</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={tipo_de_cobranca}
                      onChange={(event) => setState(prevState => ({ ...prevState, tipo_de_cobranca: event.target.value }))}
                      label="Tipo de cobrança"
                    >
                      <MenuItem key={0} value={0}><em>Escolha</em></MenuItem>
                      <MenuItem key={1} value={1}>Dinheiro</MenuItem>
                      <MenuItem key={2} value={2}>Porcentagem</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: '16px'
                    }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      color='error'
                      sx={{
                        height: '40px',
                        fontSize: '0.875rem',
                        marginBottom: '5px',
                        position: 'relative'
                      }}
                      onClick={fecharModalTarifaPadrao}
                    >
                      Voltar
                    </Button>

                    <Button
                      size="small"
                      variant="contained"
                      sx={{
                        height: '40px',
                        fontSize: '0.875rem',
                        marginBottom: '5px',
                        position: 'relative'
                      }}
                      color="success"
                      onClick={alterarTarifa}
                      disabled={state.loadAlterarTarifaPadrao}
                    >
                      {state.loadAlterarTarifaPadrao && (
                        <CircularProgress
                          size={24}
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px'
                          }}
                        />
                      )}
                      {state.loadAlterarTarifaPadrao ? 'Carregando...' : 'Alterar'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Grid>
      </Grid>

      {/* Snackbar para exibir mensagens de sucesso ou erro */}
      <Snackbar
        open={state.snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={state.snackbarSeverity} sx={{ width: '100%' }}>
          {state.snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Tarifa;
