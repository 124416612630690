import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, Navigate } from 'react-router-dom';
import {
  styled,
  createTheme,
  CssBaseline,
  Box,
  AppBar as MuiAppBar,
  Toolbar,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Divider,
  IconButton,
  Grid,
  Drawer,
  Button,
  InputLabel,
  GlobalStyles
} from '@mui/material';
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ExitToApp,
} from '@mui/icons-material';

import * as Format from './functions/Format';
import Login from './pages/Login';
import Home from './pages/home/Home';
import Contas from './pages/contas/Contas';
import Cadastrar from './pages/cadastrar/Cadastrar';
import Extrato from './pages/extrato/Extrato';
import Isencao from './pages/isencao/Isencao';
import Transferencia from './pages/transferencia/Transferencia';
import Tarifa from './pages/tarifa/Tarifa';
import Relatorios from './pages/relatorios/Relatorios';
import Painel from './pages/painel/Painel';
import NotFound from './pages/NotFound';
import { AuthProvider, AuthContext } from './contexts/AuthContext';

<GlobalStyles
  styles={{
    'html, body': {
      margin: 0,
      padding: 0,
      width: '100%',
      height: '100%',
      overflowX: 'hidden', // Remove rolagem horizontal
      paddingLeft: '0 !important', // Força a remoção do padding
      paddingRight: '0 !important',
    },
    '#root': {
      width: '100%',
      height: '100%',
    },
  }}
/>

const screens = [
  { name: 'Home', component: Home, path: '/home', icon: "apps", requireMaster: false },
  { name: 'Contas', component: Contas, path: '/contas', icon: "apps", requireMaster: false },
  { name: 'Cadastrar', component: Cadastrar, path: '/cadastrar', icon: "apps", requireMaster: false },
  { name: 'Extrato', component: Extrato, path: '/extrato', icon: "apps", requireMaster: false },
  { name: 'Isenção', component: Isencao, path: '/isencao', icon: "apps", requireMaster: false },
  { name: 'Transferência', component: Transferencia, path: '/transferencia', icon: "apps", requireMaster: false },
  { name: 'Tarifa', component: Tarifa, path: '/tarifa', icon: "apps", requireMaster: true },
  { name: 'Relatório', component: Relatorios, path: '/relatorio', icon: "apps", requireMaster: true },
  { name: 'Painel', component: Painel, path: '/painel', icon: "apps", requireMaster: true },
];

const drawerWidth = 240;

const CustomAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const CustomDrawer = styled(Drawer)(({ theme, open }) => ({
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    ...(open && {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
}));

const defaultTheme = createTheme();

const AppRoutes = ({ user, tariff, checkAuthentication }) => (
  <Routes>
    {screens.map((screen, index) => (
      // Exibe a rota somente se o perfil for 'master', ou se 'requireMaster' for false
      (!screen.requireMaster || (user?.accountData?.length > 0 && user.accountData[0]?.profile === 'master')) && (
        <Route
          key={index}
          path={screen.path}
          element={<screen.component user={user} tariff={tariff} checkAuthentication={checkAuthentication} />}
        />
      )
    ))}
  </Routes>
);

const Dashboard = () => {
  // Inicializa o estado `open` com base na largura da tela
  const [open, setOpen] = useState(window.innerWidth >= 600);
  const { user, tariff, checkAuthentication } = useContext(AuthContext);

  // Event Listener para monitorar mudanças no tamanho da janela
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setOpen(false); // Fecha o Drawer se a largura for menor que 600px
      } else {
        setOpen(true); // Abre o Drawer se a largura for maior ou igual a 600px
      }
    };

    // Adiciona o Event Listener
    window.addEventListener('resize', handleResize);

    const availableBanks = (user?.accountData || []).reduce((acc, account) => {
      if (account.chosenbank === 'qesh') acc.qesh = true;
      if (account.chosenbank === 'bmb') acc.bmb = true;
      return acc;
    }, { qesh: false, bmb: false });

    const currentChosenBank = localStorage.getItem('chosenbank');

    if (!currentChosenBank || !availableBanks[currentChosenBank]) {
      if (availableBanks.qesh) {
        localStorage.setItem('chosenbank', 'qesh'); // Prioriza 'qesh'
      } else if (availableBanks.bmb) {
        localStorage.setItem('chosenbank', 'bmb'); // Usa 'bmb'
      } 
    }

    // Remove o Event Listener ao desmontar o componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  const toggleDrawer = () => {
    setOpen(!open);
  };

  const toggleExit = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.href = '/';
  };

  const getBankColor = (chosenBank) => {
    switch (chosenBank) {
      case 'qesh':
        return '#27384E'; // Azul claro suave
      case 'bmb':
        return '#004717'; // Verde claro suave
      default:
        return '#F5F5F5'; // Cinza padrão
    }
  };


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' }, // Alinhamento responsivo
        width: '100%',
        backgroundColor: '#B6BDB9',
        minHeight: '100vh',
        overflowX: 'hidden', // Remove qualquer scroll horizontal
        padding: 0, // Remove padding interno
        margin: 0, // Remove margens externas
      }}
    >

      <CustomAppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor:
            localStorage.getItem('chosenbank') === 'qesh'
              ? '#27384E'
              : localStorage.getItem('chosenbank') === 'bmb'
                ? '#004717'
                : '#F5F5F5',
          paddingLeft: 0,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          height: { xs: '72px', sm: '60px' }, // Altura dominante (CustomDrawer Toolbar igualará)
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%', // Garantir que o Toolbar ocupe a altura total do AppBar
            padding: { xs: '6px', sm: '16px' },
          }}
        >
          {/* Botão do Menu */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: { xs: '4px', sm: '16px' },
                color: '#FFFFFF',
                fontSize: { xs: '1rem', sm: '1.5rem' },
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              noWrap
              sx={{
                fontSize: { xs: '0.8rem', sm: '1.5rem' },
                color: '#FFFFFF',
              }}
            >
              {window.innerWidth < 600 ? process.env.REACT_APP_NAME_S : process.env.REACT_APP_NAME}

            </Typography>
          </Box>

          {/* Texto do Banco Liquidante */}
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              component="h1"
              variant="h5"
              sx={{
                fontSize: { xs: '0.7rem', sm: '1.2rem' },
                color: '#FFFFFF',
              }}
            >
              {window.innerWidth < 600 ? `LQ. ` : `Você está usando o banco liquidante: `}
              <Typography
                component="span"
                sx={{
                  fontWeight: 'bold',
                  fontSize: { xs: '0.9rem', sm: '1.5rem' },
                  color: '#FFFFFF',
                }}
              >
                {localStorage.getItem('chosenbank')?.toUpperCase()}
              </Typography>
            </Typography>
          </Box>

          {/* Botão de Sair */}
          <IconButton
            onClick={toggleExit}
            sx={{
              color: '#FFFFFF',
              fontSize: { xs: '1rem', sm: '1.5rem' },
            }}
          >
            <ExitToApp />
          </IconButton>
        </Toolbar>
      </CustomAppBar>

      <CustomDrawer
        variant={open ? 'persistent' : 'temporary'}
        open={open}
        sx={{
          '& .MuiDrawer-paper': {
            width: { xs: '100%', sm: drawerWidth },
            padding: 0,
            margin: 0,
            boxSizing: 'border-box',
            height: '100vh', // Garante que o Drawer ocupe a altura da viewport
          },
        }}
      >
        <Toolbar
          sx={{
            height: { xs: '72px', sm: '60px' }, // Igual à altura do AppBar
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
            margin: 0,
            position: 'relative',
            backgroundColor:
              localStorage.getItem('chosenbank') === 'qesh'
                ? '#27384E'
                : localStorage.getItem('chosenbank') === 'bmb'
                  ? '#004717'
                  : '#F5F5F5',
            color: '#FFFFFF',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {user && (
            <Button
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                outline: 'none',
                color: '#FFFFFF',
                textAlign: 'left',
              }}
              onClick={checkAuthentication}
            >
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 'bold',
                  fontSize: { xs: '0.6rem', sm: '0.7rem' },
                }}
              >
                {`Gerente ${user?.userData?.name?.split(' ')[0]} id ${user?.id}`}
              </Typography>
              {/*  <Typography
                variant="caption"
                sx={{
                  fontSize: { xs: '0.5rem', sm: '0.6rem' },
                }}
              >
                {'SALDOS'}
              </Typography> */}
              <Box
                sx={{
                  display: 'flex', // Define o layout como flexbox
                  flexDirection: 'row', // Alinha os itens em linha
                  flexWrap: 'wrap', // Permite quebra de linha se o espaço for insuficiente
                  gap: '8px', // Espaçamento entre os itens
                  alignItems: 'flex-start', // Alinha os itens ao início
                  justifyContent: 'flex-start', // Alinha ao início da linha
                  width: '100%', // Garante que ocupe toda a largura do contêiner
                }}
              >
                {user?.configData?.multiplebalance?.map((balance) => (
                  <Box
                    key={balance.bank}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: { xs: '0.6rem', sm: '0.7rem' }, // Tamanho da fonte responsivo
                        color: '#FFFFFF', // Cor do texto
                        textAlign: 'left',
                      }}
                    >
                      {`${balance.bank.toUpperCase()}: ${Format.converterCentavosParaReais(balance.balance)}`}
                    </Typography>
                  </Box>
                ))}
              </Box>

            </Button>
          )}
        </Toolbar>

        <Divider />

        <List
          component="nav"
          sx={{
            height: '100vh', // Altura ajustada com base no Toolbar
            overflowY: 'auto',
            backgroundColor:
              localStorage.getItem('chosenbank') === 'qesh'
                ? '#27384E'
                : localStorage.getItem('chosenbank') === 'bmb'
                  ? '#004717'
                  : '#F5F5F5',
          }}
        >
          {screens.map((screen, index) => (
            (!screen.requireMaster || (user?.accountData?.length > 0 && user.accountData[0]?.profile === 'master')) && (
              <NavLink
                key={index}
                to={screen.path}
                onClick={() => {
                  // Fecha o Drawer se a largura da tela for menor que 600px
                  if (window.innerWidth < 600) {
                    setOpen(false);
                  }
                }}
                style={({ isActive }) => {
                  const chosenBank = localStorage.getItem('chosenbank');
                  return {
                    textDecoration: 'none',
                    color: isActive ? '#000000' : '#FFFFFF',
                    backgroundColor: isActive ? '#F5F5F5' : getBankColor(chosenBank),
                    borderRadius: '8px',
                    padding: '10px',
                    display: 'block',
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                  };
                }}
              >
                <ListItemButton>
                  <ListItemText
                    primary={screen.name}
                    primaryTypographyProps={{
                      fontSize: { xs: '1rem', md: '1.2rem' },
                      fontWeight: 'bold',
                    }}
                  />
                </ListItemButton>
              </NavLink>
            )
          ))}
        </List>
      </CustomDrawer>

      <Grid
        container
        sx={{
          flexGrow: 1,
          marginLeft: { xs: 0, sm: open ? `${drawerWidth}px` : 0 },
          width: { xs: '100%', sm: `calc(100% - ${open ? drawerWidth : 0}px)` },
          padding: 0, // Remove padding interno
          overflowX: 'hidden', // Remove qualquer scroll horizontal
        }}
        spacing={4} // Remove espaçamento entre elementos internos
      >
        <AppRoutes user={user} tariff={tariff} checkAuthentication={checkAuthentication} />
      </Grid>
    </Box>
  );
};

const App = () => (
  <Router>
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/*" element={<Dashboard />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AuthProvider>
  </Router>
);

export default App;
