import React, { useState } from 'react';
import {
  Button,
  Paper,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Funcoes from '../../functions/functions';
import * as Formatar from '../../functions/Format';

const TransferenciaInterna = ({ handleSnackbar, loading, setLoading }) => {
  const [accountFromId, setAccountFromId] = useState('');
  const [accountToId, setAccountToId] = useState('');
  const [accountFrom, setAccountFrom] = useState(null);
  const [accountTo, setAccountTo] = useState(null);
  const [transferValue, setTransferValue] = useState('');
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const [chosenbank, setChosenbank] = useState(localStorage.getItem('chosenbank'));

  const buscarContas = async () => {
    try {
      const fromResponse = await Funcoes.getUserDetail(accountFromId);
      const toResponse = await Funcoes.getUserDetail(accountToId);
      //console.log(fromResponse)
      //console.log(toResponse)
      setAccountFrom(fromResponse);
      setAccountTo(toResponse);
    } catch (error) {
      handleSnackbar('Erro ao buscar contas', 'error');
    }
  };

  const handleTransfer = async () => {
    if (!accountFromId || !accountToId || !transferValue) {
      handleSnackbar('Por favor, preencha todos os campos para transferência', 'warning');
      return;
    }

    setLoading(true);
    try {
      await Funcoes.executeTransfer(accountFromId, accountToId, transferValue, chosenbank);
      handleSnackbar('Transferência realizada com sucesso', 'success');
      setAccountFromId('');
      setAccountToId('');
      setAccountFrom(null);
      setAccountTo(null);
      setTransferValue('');
    } catch (error) {
      handleSnackbar('Erro ao realizar transferência', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleAmountChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    const floatValue = (parseInt(value, 10) / 100).toFixed(2);
    setTransferValue(floatValue);
  };

  return (
    <Accordion expanded={isAccordionExpanded} onChange={() => setIsAccordionExpanded(!isAccordionExpanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Transferência Interna</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {/* Campos de IDs das Contas */}
          <Grid item xs={6}>
            <TextField
              label="ID Conta Pagadora"
              value={accountFromId}
              onChange={(e) => setAccountFromId(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="ID Conta Recebedora"
              value={accountToId}
              onChange={(e) => setAccountToId(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={buscarContas} disabled={!accountFromId || !accountToId} fullWidth>
              Buscar Contas
            </Button>
          </Grid>

          {/* Exibir informações das Contas */}
          {accountFrom && (
            <Grid item xs={12} md={6}>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Typography variant="subtitle1">Conta Pagadora:</Typography>
                <Typography>Nome: {accountFrom.userData.name}</Typography>
                <Typography>Saldo: R$ {(() => {
                  const multipleBalance = Array.isArray(accountFrom?.configData?.multiplebalance)
                    ? accountFrom.configData.multiplebalance
                    : JSON.parse(accountFrom?.configData?.multiplebalance || '[]');
                  const balanceData = multipleBalance.find(item => item.bank === chosenbank);
                  const balance = balanceData?.balance || 0;
                  return Formatar.converterCentavosParaReais(balance);
                })()}</Typography>
              </Paper>
            </Grid>
          )}
          {accountTo && (
            <Grid item xs={12} md={6}>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Typography variant="subtitle1">Conta Recebedora:</Typography>
                <Typography>Nome: {accountTo.userData.name}</Typography>
                <Typography>Saldo: R$ {(() => {
                  const multipleBalance = Array.isArray(accountTo?.configData?.multiplebalance)
                    ? accountTo.configData.multiplebalance
                    : JSON.parse(accountTo?.configData?.multiplebalance || '[]');
                  const balanceData = multipleBalance.find(item => item.bank === chosenbank);
                  const balance = balanceData?.balance || 0;
                  return Formatar.converterCentavosParaReais(balance);
                })()}</Typography>
              </Paper>
            </Grid>
          )}

          {/* Campo para valor da transferência */}
          {accountFrom && accountTo && (
            <Grid item xs={12}>
              <TextField
                label="Valor da Transferência"
                value={transferValue}
                onChange={handleAmountChange}
                fullWidth
                type="number"
              />
            </Grid>
          )}

          {/* Botão de Transferência */}
          {accountFrom && accountTo && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleTransfer}
                fullWidth
                disabled={loading}
                startIcon={loading && <CircularProgress size={20} />}
              >
                {loading ? 'Transferindo...' : 'Transferir'}
              </Button>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default TransferenciaInterna;
