import moment from 'moment';

export function formatarDate(value) {
    return moment(new Date(value)).format("DD/MM/YYYY");
}

export function cpf_mask(value) {
    return value.replace(/\D/g, "")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
}

export function cel_mask(value) {
    value = value.replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2");

    if (value.replace(/\D/g, "").length > 10) {
        value = value.replace(/(\d{5})(\d)/, "$1-$2");
    } else {
        value = value.replace(/(\d{4})(\d)/, "$1-$2");
    }
    return value;
}

export function converterCentavosParaReais(value) {
    const reais = parseInt(value) / 100;
    return reais.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}


export function converterReaisParaCentavos(reais) {
    // Multiplica o valor em reais por 100 e arredonda para garantir que o resultado seja um número inteiro.
    return Math.round(reais * 100);
}

export function converterCentavosEmPorcentagem(centavos) {
    /* if (typeof centavos !== 'number' || centavos < 0) {
      throw new Error('Por favor, insira um valor válido em centavos.');
    } */
    return centavos / 100;
  }


export function formatarDateAnoHoraSegundo(value) {
    return moment(new Date(value)).format("YYYY-MM-DD HH:mm:ss");
}


export function validar_cpf(value) {
    // Remove todos os caracteres que não são dígitos
    value = value.replace(/\D/g, "");

    // Verifica se o CPF tem 11 dígitos ou se todos os dígitos são iguais
    if (value.length !== 11 || /^(\d)\1{10}$/.test(value)) {
        return false;
    }

    // Função para calcular o dígito verificador
    const calcularDigito = (base, length) => {
        let sum = 0;
        for (let i = 0; i < length; i++) {
            sum += parseInt(base[i]) * (length + 1 - i);
        }
        let remainder = (sum * 10) % 11;
        return remainder === 10 || remainder === 11 ? 0 : remainder;
    };

    // Calcula os dígitos verificadores
    const digito1 = calcularDigito(value, 9);
    const digito2 = calcularDigito(value, 10);

    // Verifica se os dígitos calculados são iguais aos dígitos do CPF
    return digito1 === parseInt(value[9]) && digito2 === parseInt(value[10]);
}


export function validar_cnpj(value) {
    // Remove todos os caracteres que não são dígitos
    value = value.replace(/\D/g, "");

    // Verifica se o valor está vazio ou se não tem 14 dígitos
    if (value === "" || value.length !== 14) {
        return false;
    }

    // Verifica se todos os dígitos são iguais
    if (/^(\d)\1+$/.test(value)) {
        return false;
    }

    // Função para calcular o dígito verificador
    const calcularDigito = (base, length) => {
        let soma = 0;
        let pos = length - 7;
        for (let i = length; i >= 1; i--) {
            soma += base.charAt(length - i) * pos--;
            if (pos < 2) pos = 9;
        }
        const resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        return resultado;
    };

    // Calcula os dígitos verificadores
    const tamanho = value.length - 2;
    const numeros = value.substring(0, tamanho);
    const digitos = value.substring(tamanho);

    const digito1 = calcularDigito(numeros, tamanho);
    const digito2 = calcularDigito(numeros + digito1, tamanho + 1);

    // Verifica se os dígitos calculados são iguais aos dígitos do CNPJ
    return digito1 == digitos.charAt(0) && digito2 == digitos.charAt(1);
}



