import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Box,
  Button,
} from '@mui/material';
import * as Formatar from '../../../../functions/Format';

const IsencaoDialog = ({
  open,
  onClose,
  tariff,
  casadeaposta,
  login,
  password,
  motivo,
  selectedUserIsencao,
  onChangeAposta,
  onChangeLogin,
  onChangePassword,
  onChangeMotivo,
  onSolicitarIsencao,
  formatDocumento,
}) => {
  // Função auxiliar para formatar a tarifa
  const getTarifaValue = () => {
    if (Array.isArray(tariff)) {
      const tarifaItem = tariff.find((item) => item.tariffkey === "tarifa_criar_pedido_isencao");
      if (tarifaItem) {
        return tarifaItem.typeofcharge === 1
          ? Formatar.converterCentavosParaReais(tarifaItem.value)
          : `${Formatar.converterCentavosEmPorcentagem(tarifaItem.value)}%`;
      }
    }
    return '';
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h6" gutterBottom>
          {'Tarifa para Solicitar Isenção: ' + getTarifaValue()}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Box>
          <TextField
            size="small"
            autoFocus
            margin="dense"
            name="casadeaposta"
            label="Casa de Aposta"
            type="text"
            fullWidth
            variant="standard"
            value={casadeaposta}
            onChange={onChangeAposta}
            required
          />
          <TextField
            size="small"
            margin="dense"
            name="login"
            label="Login"
            type="text"
            fullWidth
            variant="standard"
            value={login}
            onChange={onChangeLogin}
            required
          />
          <TextField
            size="small"
            margin="dense"
            name="password"
            label="Senha"
            type="text"
            fullWidth
            variant="standard"
            value={password}
            onChange={onChangePassword}
            required
          />
          <TextField
            size="small"
            margin="dense"
            name="motivo"
            label="Motivo"
            type="text"
            fullWidth
            variant="standard"
            value={motivo}
            onChange={onChangeMotivo}
            required
          />
          <TextField
            size="small"
            margin="dense"
            label="CPF"
            type="text"
            fullWidth
            variant="standard"
            value={formatDocumento(selectedUserIsencao?.userData?.document)}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            size="small"
            margin="dense"
            label="Nome"
            type="text"
            fullWidth
            variant="standard"
            value={selectedUserIsencao?.userData?.name}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button
          size="small"
          variant="contained"
          color="error"
          onClick={onClose}
          sx={{ color: 'white' }}
        >
          Cancelar
        </Button>

        <Button
          size="small"
          variant="contained"
          color="success"
          onClick={onSolicitarIsencao}
          sx={{ color: 'white' }}
        >
          Solicitar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IsencaoDialog;
