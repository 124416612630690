import React, { useState, useEffect } from 'react';
import * as Funcoes from '../../functions/functions';
import {
  Button,
  Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  Snackbar,
  Alert,
  TextField,
  CircularProgress
} from '@mui/material';
import { bancos } from '../../components/isbp'; // Importa a lista de bancos com ISPB
import * as Format from "../../functions/Format";

const Transferencia = ({ tariff: initialTariff, checkAuthentication }) => {
  const [pixKeys, setPixKeys] = useState([]); // Armazena as chaves Pix disponíveis
  const [selectedPixKey, setSelectedPixKey] = useState(''); // Armazena a chave Pix selecionada
  const [pixDetails, setPixDetails] = useState(null); // Detalhes da chave Pix após consulta
  const [transferAmount, setTransferAmount] = useState(''); // Valor da transferência
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Controle do Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(''); // Mensagem do Snackbar
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Severidade do Snackbar
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [tariff, setTariff] = useState([])//useState(Array.isArray(initialTariff) ? initialTariff : JSON.parse(localStorage.getItem('tariff')));
  const [loading, setLoading] = useState(false); // Estado de carregamento para pesquisa Pix
  const [loadingTransfer, setLoadingTransfer] = useState(false); // Estado de carregamento para transferência
  const [chosenbank, setChosenbank] = useState(localStorage.getItem('chosenbank'));

  useEffect(() => {
    async function fetchDatacheck() {
      await checkAuthentication();
      await getTariffUser();
    }
    fetchDatacheck();
  }, []);

  // Filtra as chaves Pix com status = 2
  useEffect(() => {
    //console.log(user)
    const filteredPixKeys = user?.configData?.keypix?.length >= 1 ? JSON.parse(user.configData.keypix).filter((key) => key.status == 2) : [];
    setPixKeys(filteredPixKeys);
  }, [user]);

  const getTariffUser = async () => {
    const tariff = await Funcoes.getTariffUser(user.id);
    //console.log(tariff);
    setTariff(tariff);
  };

  // Função para buscar o nome do banco com base no ISPB
  const buscarNomeBancoPorISPB = (ispb) => {
    const bancoEncontrado = bancos.find((banco) => banco.ispb === ispb);
    return bancoEncontrado ? bancoEncontrado.name : 'Banco não encontrado';
  };

  // Função para buscar a chave Pix quando o botão for clicado
  const handleSearchPixKey = async () => {
    if (!selectedPixKey) {
      setSnackbarMessage('Selecione uma chave Pix');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true); // Inicia o estado de carregamento

    try {
      const pixDetailResponse = await Funcoes.consultarPix({
        userId: user.id,
        chavePix: selectedPixKey,
        typekey: 1,
        chosenbank
      });

      const detalhesPix = pixDetailResponse.data;
      const nomeBanco = buscarNomeBancoPorISPB(detalhesPix.ispb); // Busca o nome do banco
      setPixDetails({ ...detalhesPix, nomeBanco }); // Adiciona o nome do banco aos detalhes da chave Pix
    } catch (error) {
      //console.error('Erro ao consultar chave Pix:', error);
      setSnackbarMessage('Erro ao consultar chave Pix');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false); // Finaliza o estado de carregamento
    }
  };

  // Função para formatar o valor conforme o usuário digita
  const handleAmountChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove tudo que não for número
    if (value.length > 10) {
      value = value.slice(0, 10); // Limita o valor a no máximo 10 dígitos
    }
    const floatValue = (parseInt(value, 10) / 100).toFixed(2); // Converte para valor float com duas casas decimais
    setTransferAmount(floatValue); // Atualiza o estado com o valor formatado
  };

  const handleTransfer = async () => {
    if (!transferAmount || !pixDetails) {
      setSnackbarMessage('Insira um valor válido para transferência');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    setLoadingTransfer(true); // Inicia o estado de carregamento da transferência

    try {
      const response = await Funcoes.realizarTransferencia({
        user,
        pixKey: pixDetails,
        amount: parseFloat(transferAmount), // Converte o valor da transferência para float
        demand: user.accountData[0]?.profile === 'master' ? 0 : 1,
        tariffkey: "tarifa_saque_conta_gerente",
        chosenbank
      });

      //console.log(response);

      if (response.sucesso) {
        await checkAuthentication();
        setSnackbarMessage('Transferência realizada com sucesso!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setSelectedPixKey(''); // Limpa a chave Pix selecionada
        setPixDetails(null); // Limpa os detalhes da chave Pix
        setTransferAmount(''); // Limpa o valor da transferência
        setLoadingTransfer(false); // Finaliza o estado de carregamento da transferência
        //consultarMov(response.data.data.endToEnd);
      }
      else {
        setSnackbarMessage('Erro ao realizar transferência');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setLoadingTransfer(false);
      }

    } catch (error) {
      //console.error('Erro ao realizar transferência:', error);
      setSnackbarMessage('Erro ao realizar transferência');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setLoadingTransfer(false);
    }
  };

  const consultarMov = async (id) => {
    const result = await Funcoes.consultarMovimentacao(id);
    //console.log(result);

    if (result == 0 || result?.status !== "COMPLETED") {
      setTimeout(() => {
        consultarMov(id);
      }, 3000);
    } else {
      await checkAuthentication();
      setSnackbarMessage('Transferência realizada com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setSelectedPixKey(''); // Limpa a chave Pix selecionada
      setPixDetails(null); // Limpa os detalhes da chave Pix
      setTransferAmount(''); // Limpa o valor da transferência
      setLoadingTransfer(false); // Finaliza o estado de carregamento da transferência
    }
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 17, mb: 4 }}>
    <Grid container spacing={3}>
      {/* Título */}
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          {'Transferência via Chave Pix. Tarifa para Saque ' +
            (() => {
              if (Array.isArray(tariff)) {
                const tarifaItem = tariff.find((item) => item.tariffkey === 'tarifa_saque_conta_gerente');
                if (tarifaItem) {
                  return tarifaItem.typeofcharge === 1
                    ? Format.converterCentavosParaReais(tarifaItem.value)
                    : Format.converterCentavosEmPorcentagem(tarifaItem.value) + '%';
                }
              }
              return '';
            })()}
        </Typography>
      </Grid>
  
      {/* Select de Chave Pix */}
      <Grid item xs={12} sm={8}>
        <Select
          value={selectedPixKey}
          onChange={(e) => setSelectedPixKey(e.target.value)}
          fullWidth
          displayEmpty
        >
          <MenuItem value="" disabled>
            Selecione uma chave Pix
          </MenuItem>
          {pixKeys.map((key) => (
            <MenuItem key={key.keypix} value={key.keypix}>
              {key.keypix}
            </MenuItem>
          ))}
        </Select>
      </Grid>
  
      {/* Botão de Pesquisa */}
      <Grid item xs={12} sm={4}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSearchPixKey}
          disabled={!selectedPixKey || loading}
          sx={{
            height: '56px', // Uniformiza altura com Select
          }}
        >
          {loading ? <CircularProgress size={24} /> : 'Pesquisar Chave Pix'}
        </Button>
      </Grid>
  
      {/* Detalhes da Chave Pix */}
      {pixDetails && (
        <>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Detalhes da Chave Pix:
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Proprietário</TableCell>
                    <TableCell>Banco</TableCell>
                    <TableCell>Conta</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{pixDetails.ownerName}</TableCell>
                    <TableCell>{pixDetails.nomeBanco}</TableCell>
                    <TableCell>{`${pixDetails.accountBranch} - ${pixDetails.accountNumber}`}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
  
          {/* Input para Valor da Transferência */}
          <Grid item xs={12}>
            <TextField
              label="Valor da Transferência"
              type="text"
              value={transferAmount}
              onChange={handleAmountChange}
              fullWidth
            />
          </Grid>
  
          {/* Botão para Realizar Transferência */}
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleTransfer}
              disabled={loadingTransfer}
              sx={{
                height: '56px', // Uniformiza altura com Select
              }}
            >
              {loadingTransfer ? <CircularProgress size={24} /> : 'Realizar Transferência'}
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  
    {/* Snackbar */}
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={() => setSnackbarOpen(false)}
    >
      <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  </Container>
  
  );
};

export default Transferencia;
