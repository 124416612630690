import axios from 'axios';

// Criação da instância do Axios
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE,
});

// Interceptor de requisição para adicionar o token de autenticação
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor de resposta para formatar os erros
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Verifica se o erro tem uma resposta do servidor
    if (error.response) {
      // Cria um novo erro com apenas status e message, sem detalhes da API
      const formattedError = {
        status: error.response.status,
        message: error.response.data.message || 'Erro desconhecido'
      };
      // Lança o erro formatado
      return Promise.reject(formattedError);
    }
    // Se o erro não tem uma resposta do servidor, cria um erro genérico
    return Promise.reject({
      status: 500,
      message: 'Erro interno do servidor'
    });
  }
);

export default api;
