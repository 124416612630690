import React, { useState } from 'react';
import {
  Button,
  Paper,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Funcoes from '../../functions/functions';

const ConsultarContasLQ = ({ handleSnackbar, loading, setLoading }) => {
  const [startDate, setStartDate] = useState(() => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const sevenDaysAgo = new Date(now);
    sevenDaysAgo.setDate(now.getDate() - 7);
    return sevenDaysAgo.toISOString().split('T')[0];
  });
  const [endDate, setEndDate] = useState(() => {
    const now = new Date();
    now.setHours(23, 59, 59, 999);
    return now.toISOString().split('T')[0];
  });
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [chosenbank, setChosenbank] = useState(localStorage.getItem('chosenbank'));
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === 'asc';
    setOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = (data || []).slice().sort((a, b) => {
    if (!orderBy) return 0;

    const aValue = a[orderBy] || '';
    const bValue = b[orderBy] || '';

    if (order === 'asc') {
      return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
    } else {
      return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
    }
  });

  const consultarContasLQ = async () => {
    setLoading(true);
    try {
      const response = await Funcoes.getUsersByLQ(startDate, endDate, itemsPerPage, page, chosenbank);
      if (chosenbank === 'qesh') {
        setData(response.operators);

      } else if (chosenbank === 'bmb') {
        setData(response.accounts);
        handleDeleteAccount(response.accounts);
      }

      handleSnackbar('Dados consultados com sucesso', 'success');
    } catch (error) {
      handleSnackbar('Erro ao consultar dados', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async (accounts) => {
    if (!accounts || accounts.length === 0) {
      //console.log("No accounts provided");
      return [];
    }
  
    // Filtra apenas os com status 'TRASH' e mapeia os dados necessários
    const accountsDelete = accounts
      .filter(account => account.status === 'TRASH')
      .map(account => ({
        account: account.account_id,
        document: account.document,
      }));
  
    //console.log("Accounts to delete:", accountsDelete);
  
    // Loop para deletar as contas
    const results = [];
    for (const accountToDelete of accountsDelete) {
      try {
        const response = await Funcoes.deleteUserAccount({ accountId: accountToDelete.account });
        //console.log(`Account ${accountToDelete.account} deleted successfully:`, response);
        results.push({ account: accountToDelete.account, success: true, response });
      } catch (error) {
        //console.error(`Failed to delete account ${accountToDelete.account}:`, error);
        results.push({ account: accountToDelete.account, success: false, error });
      }
    }
  
    // Retorna os resultados de cada tentativa de deleção
    //console.log(results);
  };
  



  const handleGenerateLink = async (account_id) => {
    setLoading(true);
    try {
      const response = await Funcoes.handleGenerateLink(account_id, chosenbank);

      if (response?.data?.sucesso && response?.data?.data?.kyc_link) {
        const kycLink = response.data.data.kyc_link;
        // Exibe o link diretamente no Snackbar
        handleSnackbar(`Link KYC gerado com sucesso! ${kycLink}`, 'success');
      } else {
        handleSnackbar(
          'Falha ao gerar o link KYC. Verifique os dados enviados.',
          'error'
        );
      }
    } catch (error) {
      handleSnackbar(`Erro ao gerar o link`, 'error');
    } finally {
      setLoading(false);
    }
  };



  const renderTable = () => {
    if (chosenbank === 'bmb') {
      return (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'account_id'}
                    direction={orderBy === 'account_id' ? order : 'asc'}
                    onClick={() => handleSort('account_id')}
                  >
                    ID da Conta
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'document'}
                    direction={orderBy === 'document' ? order : 'asc'}
                    onClick={() => handleSort('document')}
                  >
                    Documento
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order : 'asc'}
                    onClick={() => handleSort('name')}
                  >
                    Nome
                  </TableSortLabel>
                </TableCell>
                <TableCell>Conta Bancária</TableCell>
                <TableCell>ISPB</TableCell>
                <TableCell>Agência</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'status'}
                    direction={orderBy === 'status' ? order : 'asc'}
                    onClick={() => handleSort('status')}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'created_at'}
                    direction={orderBy === 'created_at' ? order : 'asc'}
                    onClick={() => handleSort('created_at')}
                  >
                    Criado Em
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((account) => (
                <TableRow key={account.account_id}>
                  <TableCell>{account.account_id}</TableCell>
                  <TableCell>{account.document}</TableCell>
                  <TableCell>{account.name}</TableCell>
                  <TableCell>{account.bankAccount || '-'}</TableCell>
                  <TableCell>{account.bankIspb || '-'}</TableCell>
                  <TableCell>{account.bankBranch}</TableCell>
                  <TableCell>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography variant="body2">{account.status}</Typography>
                      {(account.status === 'PENDING_SUBMIT_DOC' || account.status === 'REFUSED_KYC' || account.status === 'REFUSED_BASS') && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => handleGenerateLink(account.account_id)}
                          sx={{ mt: 1 }}
                        >
                          Gerar Link
                        </Button>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>{account.type}</TableCell>
                  <TableCell>{account.created_at}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }

    if (chosenbank === 'qesh') {
      return (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'id'}
                    direction={orderBy === 'id' ? order : 'asc'}
                    onClick={() => handleSort('id')}
                  >
                    ID do Operador
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order : 'asc'}
                    onClick={() => handleSort('name')}
                  >
                    Nome do Operador
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'status'}
                    direction={orderBy === 'status' ? order : 'asc'}
                    onClick={() => handleSort('status')}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'createdAt'}
                    direction={orderBy === 'createdAt' ? order : 'asc'}
                    onClick={() => handleSort('createdAt')}
                  >
                    Criado Em
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((operator) => (
                <TableRow key={operator.id}>
                  <TableCell>{operator.id}</TableCell>
                  <TableCell>{operator.name}</TableCell>
                  <TableCell>{operator.status}</TableCell>
                  <TableCell>{new Date(operator.createdAt).toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }

    return null;
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Consultar Contas LQ</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Data de Início"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Data de Término"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Itens por Página"
              type="number"
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(parseInt(e.target.value, 10))}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Página"
              type="number"
              value={page}
              onChange={(e) => setPage(parseInt(e.target.value, 10))}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={consultarContasLQ}
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              {loading ? 'Consultando...' : 'Consultar'}
            </Button>
          </Grid>
        </Grid>

        {data && renderTable()}
      </AccordionDetails>
    </Accordion>
  );
};

export default ConsultarContasLQ;
