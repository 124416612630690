import axios from 'axios';
import { validateToken, getUsers, kyc, zipcode, createUser } from '../../../functions/functions';

export async function fetchContas(userId, page, pageSize, sortField, sortOrder, onlyManagerAccounts, chosenbank) {
  const params = { page, pageSize, managerId: userId, onlyManagerAccounts, chosenbank };

  if (sortField) params.sortField = sortField;
  if (sortOrder) params.sortOrder = sortOrder;
  if (onlyManagerAccounts) params.onlyManagerAccounts = onlyManagerAccounts;
  if (chosenbank) params.chosenbank = chosenbank;

  // Chama a função getUsers passando os parâmetros
  const users = await getUsers(params);

  return users;
}


export async function fetchKYC(documento) {
  const response = await kyc(documento);
  return response;
}

export async function fetchCEP(cep) {
  const response = await zipcode(cep);
  return response;
}

export async function createAccount(data) {
  const response = await createUser(data);
  return response;
}
