import React, { useState, useEffect } from 'react';
import * as Formatar from '../../functions/Format';
import * as Funcoes from '../../functions/functions';
import {
  Box,
  Button,
  Container,
  Paper,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
import moment from 'moment';

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const Extrato = ({ user, checkAuthentication }) => {
  const [dataDe, setDataDe] = useState(moment().subtract(1, 'days').format('YYYY-MM-DD'));
  const [dataAte, setDataAte] = useState(moment().format('YYYY-MM-DD'));
  const [extrato, setExtrato] = useState([]);
  const [totalItems, setTotalItems] = useState(0); // Total de itens retornados pelo servidor
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [chosenbank, setChosenbank] = useState(localStorage.getItem('chosenbank'));

  useEffect(() => {
    async function fetchDatacheck() {
      await checkAuthentication();
    }
    fetchDatacheck();
  }, []);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    buscarExtrato(newPage, rowsPerPage); // Busca os dados da nova página
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    buscarExtrato(0, newRowsPerPage); // Busca os dados com a nova quantidade de linhas por página
  };

  const buscarExtrato = async (newPage = page, newRowsPerPage = rowsPerPage) => {
    try {
      const fromDate = moment(dataDe).startOf('day').format('YYYY-MM-DD HH:mm:ss');
      const toDate = moment(dataAte).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      const account = user?.accountData?.find(item => item.chosenbank === chosenbank).id;
      //const  = user?.accountData?.[0]?.id;

      const extratoResponse = await Funcoes.fetchExtrato(account, fromDate, toDate, newPage, newRowsPerPage);

      // Atualiza os dados do extrato e o número total de itens
      setExtrato(extratoResponse.data); // Atualiza com os novos dados
      setTotalItems(extratoResponse.totalItems); // Atualiza o número total de registros

      // Verifica se não há transações no extrato
      if (extratoResponse.data.length === 0) {
        setSnackbarMessage('Nenhuma transação encontrada.');
        setSnackbarSeverity('info');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('Erro ao buscar extrato');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };


  return (
    <Container maxWidth="xl" sx={{ mt: 14, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Extrato de Transações
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Data De"
            type="date"
            value={dataDe}
            onChange={(e) => setDataDe(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 5, // Define bordas arredondadas
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Data Até"
            type="date"
            value={dataAte}
            onChange={(e) => setDataAte(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 5, // Define bordas arredondadas
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={() => buscarExtrato(page, rowsPerPage)} sx={{
            borderRadius: 5, // Define bordas arredondadas para o botão
          }}>
            Buscar Extrato
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="extrato table">
              <TableHead>
                <TableRow>
                  <TableCell sortDirection={orderBy === 'description' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'description'}
                      direction={orderBy === 'description' ? order : 'asc'}
                      onClick={() => handleRequestSort('description')}
                    >
                      Descrição
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sortDirection={orderBy === 'value' ? order : false} align="right">
                    <TableSortLabel
                      active={orderBy === 'value'}
                      direction={orderBy === 'value' ? order : 'asc'}
                      onClick={() => handleRequestSort('value')}
                    >
                      Valor R$
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sortDirection={orderBy === 'createdAt' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'createdAt'}
                      direction={orderBy === 'createdAt' ? order : 'asc'}
                      onClick={() => handleRequestSort('createdAt')}
                    >
                      Data/Hora
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(extrato || [], getComparator(order, orderBy))
                  .map((item) => (
                    <TableRow
                      key={item.id}
                      sx={{
                        backgroundColor: item.value > 0 ? 'lightgreen' : 'lightcoral',
                      }}
                    >
                      <TableCell>{item.description}</TableCell>
                      <TableCell align="right">{Formatar.converterCentavosParaReais(item.value)}</TableCell>
                      <TableCell>{Formatar.formatarDateAnoHoraSegundo(item.createdAt)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            component="div"
            count={totalItems}  // O total de registros vem do servidor
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Extrato;
