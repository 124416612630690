import React, { useState, useEffect } from 'react';
import * as Formatar from '../../functions/Format';
import { getAllExemptions, updateExemptions } from '../../functions/functions';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Container,
  Paper,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Checkbox,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';

const STATUS = {
  ABERTA: 1,
  APROVADA: 2,
  REPROVADA: 3,
};

// Mapeamento de nomes de campos para rótulos amigáveis
const HEADERS = [
  { id: 'managerAccountName', label: 'Conta Gerente' },
  { id: 'originAccountName', label: 'Conta Filha' },
  { id: 'originAccountDocument', label: 'Doc Conta Filha' },
  { id: 'bettinghouse', label: 'Casa de Aposta' },
  { id: 'login', label: 'Login' },
  { id: 'password', label: 'Senha' },
  { id: 'reason', label: 'Motivo' },
  { id: 'value', label: 'Valor R$' },
  { id: 'createdAt', label: 'Data/Hora' },
];

// Função stableSort para ordenar a tabela de forma estável
function stableSort(array, comparator) {
  const stabilizedArray = array.map((el, index) => [el, index]);
  stabilizedArray.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedArray.map((el) => el[0]);
}

// Função para retornar o comparador de ordenação
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Comparador de ordenação decrescente
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const Isencao = ({ user, checkAuthentication }) => {
  const [exemptions, setExemptions] = useState({
    abertas: [],
    aprovadas: [],
    reprovadas: [],
  });
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const [chosenbank, setChosenbank] = useState(localStorage.getItem('chosenbank'));

  useEffect(() => {
    const fetchData = async () => await checkAuthentication();
    fetchData();
  }, []);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setPage(0);
    setRowsPerPage(10);

    if (isExpanded) {
      fetchExemptions(panel); // Carrega os dados com base no status
    }
  };

  const handleRequestSort = (property) => {
    setOrder((prevOrder) => (orderBy === property && prevOrder === 'asc' ? 'desc' : 'asc'));
    setOrderBy(property);
  };

  const handleSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const fetchExemptions = async (status) => {
    //console.log(status);
    try {
      const account = user?.id;
      if (!account) throw new Error('ID da conta não encontrado. Verifique se o usuário está logado.');

      const isencoes = await getAllExemptions(account, status, chosenbank);
      if (!Array.isArray(isencoes)) throw new Error('Nenhuma isenção recebida ou formato inválido');

      setExemptions((prev) => ({
        ...prev,
        [status === STATUS.ABERTA ? 'abertas' : status === STATUS.APROVADA ? 'aprovadas' : 'reprovadas']: isencoes,
      }));
    } catch (error) {
      handleSnackbar(error.message, 'error');
      setExemptions((prev) => ({ ...prev, [status === STATUS.ABERTA ? 'abertas' : status === STATUS.APROVADA ? 'aprovadas' : 'reprovadas']: [] }));
    }
  };

  const handleUpdateExemptions = async (status) => {
    if (selected.length === 0) return handleSnackbar('Nenhuma isenção selecionada.', 'warning');

    setLoading(true); // Ativa o estado de carregamento
    try {
      const response = await updateExemptions(selected, status, chosenbank);
      //console.log(response);
      if (response) {
        await checkAuthentication();
        await fetchExemptions(STATUS.ABERTA); // Recarrega as isenções abertas após a operação
        await fetchExemptions(status); // Recarrega a lista de isenções do status atualizado
        setSelected([]);
        handleSnackbar(`Isenções ${status === STATUS.APROVADA ? 'aprovadas' : 'reprovadas'} com sucesso!`, 'success');
      }
    } catch {
      handleSnackbar('Erro ao atualizar isenções', 'error');
    } finally {
      setLoading(false); // Desativa o estado de carregamento
    }
  };


  const handleSelect = (id) => {
    setSelected((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const renderTable = (data, backgroundColor, showActions = false) => (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="exemption table">
        <TableHead>
          <TableRow>
            {HEADERS.map((header) => (
              <TableCell key={header.id} sortDirection={orderBy === header.id ? order : false}>
                <TableSortLabel
                  active={orderBy === header.id}
                  direction={orderBy === header.id ? order : 'asc'}
                  onClick={() => handleRequestSort(header.id)}
                >
                  {header.label}
                </TableSortLabel>
              </TableCell>
            ))}
            {showActions && (
              <TableCell align="center" padding="checkbox">
                <Box sx={{ display: 'flex', gap: 1, position: 'relative' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleUpdateExemptions(STATUS.APROVADA)}
                    disabled={loading} // Desativa o botão se estiver carregando
                  >
                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Aprovar'}
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleUpdateExemptions(STATUS.REPROVADA)}
                    disabled={loading} // Desativa o botão se estiver carregando
                  >
                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Reprovar'}
                  </Button>
                </Box>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(data, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item) => (
              <TableRow key={item.id} hover sx={{ backgroundColor }}>
                <TableCell>{item.managerAccountName}</TableCell>
                <TableCell>{item.originAccountName}</TableCell>
                <TableCell>{item.originAccountDocument}</TableCell>
                <TableCell>{item.bettinghouse}</TableCell>
                <TableCell>{item.login}</TableCell>
                <TableCell>{item.password}</TableCell>
                <TableCell>{item.reason}</TableCell>
                <TableCell align="right">{Formatar.converterCentavosParaReais(item.value)}</TableCell>
                <TableCell>{Formatar.formatarDateSave(item.createdAt)}</TableCell>
                {showActions && (
                  <TableCell align="center" padding="checkbox">
                    <Checkbox
                      checked={selected.includes(item.id)}
                      onChange={() => handleSelect(item.id)}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e, newPage) => setPage(newPage)}
        onRowsPerPageChange={(e) => setRowsPerPage(parseInt(e.target.value, 10))}
      />
    </TableContainer>
  );

  return (
    <Container maxWidth="xl" sx={{ mt: 12, mb: 4 }}>
      <Box sx={{ flexGrow: 1, mb: 2, mt: 2 }}>
        <h2>Isenção</h2>
      </Box>

      {[
        {
          label: 'Isenções em Aberto',
          data: exemptions.abertas,
          status: STATUS.ABERTA,
          color: 'white',
          showActions: user.accountData[0]?.profile === 'master',
        },
        {
          label: 'Pedidos Aprovados',
          data: exemptions.aprovadas,
          status: STATUS.APROVADA,
          color: 'lightgreen',
          showActions: false,
        },
        {
          label: 'Pedidos Reprovados',
          data: exemptions.reprovadas,
          status: STATUS.REPROVADA,
          color: 'lightcoral',
          showActions: false,
        },
      ].map((section, index) => (
        <Accordion key={index} expanded={expanded === section.status} onChange={handleAccordionChange(section.status)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{section.label}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {renderTable(section.data, section.color, section.showActions)}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{
          width: '100%',
          fontSize: '1.50rem', // Aumenta em 15%
        }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Isencao;
