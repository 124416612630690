import React, { useState } from 'react';
import {
  Button,
  Paper,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Funcoes from '../../functions/functions';
import * as Formatar from '../../functions/Format';

const ConsultarExtrato = ({ handleSnackbar, loading, setLoading, handleOpenComprovante }) => {
  const [startDate, setStartDate] = useState(() => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const sevenDaysAgo = new Date(now);
    sevenDaysAgo.setDate(now.getDate() - 7);
    return sevenDaysAgo.toISOString().split('T')[0];
  });
  const [endDate, setEndDate] = useState(() => {
    const now = new Date();
    now.setHours(23, 59, 59, 999);
    return now.toISOString().split('T')[0];
  });
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [paymentAccount, setPaymentAccount] = useState('');
  const [movementsData, setMovementsData] = useState(null);
  const [chosenbank, setChosenbank] = useState(localStorage.getItem('chosenbank'));

  const handleCPFInput = (value) => {
    // Remove tudo que não for número
    const numericValue = value.replace(/\D/g, '');
    // Aplica máscara de CPF (XXX.XXX.XXX-XX)
    const formattedValue = numericValue
      .replace(/^(\d{3})(\d)/, '$1.$2')
      .replace(/^(\d{3}\.\d{3})(\d)/, '$1.$2')
      .replace(/^(\d{3}\.\d{3}\.\d{3})(\d{1,2})$/, '$1-$2')
      .slice(0, 14); // Limita o comprimento
    setPaymentAccount(formattedValue);
  };

  const consultarMovimentos = async () => {
    setLoading(true);
    try {
      // Enviar apenas números para a função
      const response = await Funcoes.getMovimentsService(
        startDate,
        endDate,
        itemsPerPage,
        page,
        paymentAccount.replace(/\D/g, ''),
        chosenbank
      );
      console.log(response);

      let dados = response?.data || response;
      setMovementsData(dados);
      handleSnackbar('Movimentos consultados com sucesso', 'success');
    } catch (error) {
      handleSnackbar('Erro ao consultar movimentos', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Consultar Extrato LQ</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField label="Data de Início" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextField label="Data de Término" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextField label="Itens por Página" type="number" value={itemsPerPage} onChange={(e) => setItemsPerPage(parseInt(e.target.value, 10))} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextField label="Página" type="number" value={page} onChange={(e) => setPage(parseInt(e.target.value, 10))} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="CPF"
              value={paymentAccount}
              onChange={(e) => handleCPFInput(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={consultarMovimentos}
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              {loading ? 'Consultando...' : 'Consultar'}
            </Button>
          </Grid>
        </Grid>

        {movementsData && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Ref</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Data de Criação</TableCell>
                  <TableCell>Devedor</TableCell>
                  <TableCell>Credor</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {movementsData.transfers.map((transfer) => (
                  <TableRow key={transfer.id}>
                    <TableCell>{transfer.endToEnd}</TableCell>
                    <TableCell>{transfer.status}</TableCell>
                    <TableCell>{Formatar.formatarMoeda(transfer.amount)}</TableCell>
                    <TableCell>{Formatar.formatarDateAnoHoraSegundo(transfer.createdAt)}</TableCell>
                    <TableCell>{`${transfer.debtor.name} - ${transfer.debtor.document}`}</TableCell>
                    <TableCell>{`${transfer.creditor.name} - ${transfer.creditor.document}`}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleOpenComprovante(transfer)}
                      >
                        Gerar Comprovante
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default ConsultarExtrato;
