import React, { Component } from 'react';
import './Alert.css'; // Arquivo CSS para estilização

class Alert extends Component {
  render() {
    const { message, onClose } = this.props;
    return (
      <div className="alert-overlay">
        <div className="alert-box">
          <p>{message}</p>
          <button onClick={onClose} className="btn btn-primary">Fechar</button>
        </div>
      </div>
    );
  }
}

export default Alert;
