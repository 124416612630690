import React from 'react';

class NotFound extends React.Component {
  render() {
    return (
      <div className="container">
        <h1>404 - Not Found</h1>
        <p>The page you are looking for does not exist.</p>
      </div>
    );
  }
}

export default NotFound;
