import moment from 'moment';

/**
 * Formata uma data para o formato "DD/MM/YYYY".
 * @param {string} data - A data a ser formatada.
 * @returns {string} - A data formatada.
 */
export function formatarDate(data) {
  let today = new Date(data).toLocaleDateString("pt-BR");
  return today;
}

/**
 * Formata uma data no formato "YYYY-MM-DD" para "DD/MM/YYYY".
 * @param {string} data - A data a ser formatada.
 * @returns {string} - A data formatada.
 */
export function formatarDateGerenciadorBoletos(data) {
  if (data) {
    let [ano, mes, dia] = data.split("-");
    return `${dia}/${mes}/${ano}`;
  }
}

/**
 * Formata uma data no formato "YYYY-MM-DD" para "YYYY/MM/DD".
 * @param {string} data - A data a ser formatada.
 * @returns {string} - A data formatada.
 */
export function formatarDateGerenciadorBoletosComparativo(data) {
  let [ano, mes, dia] = data.split("-");
  return `${ano}/${mes}/${dia}`;
}

/**
 * Formata uma data no formato "DD/MM/YYYY" para "YYYY/MM/DD".
 * @param {string} data - A data a ser formatada.
 * @returns {string} - A data formatada.
 */
export function formatarDateDiaMesAno(data) {
  let [dia, mes, ano] = data.split("/");
  return `${ano}/${mes}/${dia}`;
}

/**
 * Formata um valor para o formato de moeda brasileira.
 * @param {number} valor - O valor a ser formatado.
 * @returns {string} - O valor formatado.
 */
export function formatarMoeda(valor) {
  return Number(valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

/**
 * Converte centavos para reais.
 * @param {number} centavos - O valor em centavos a ser convertido.
 * @returns {string} - O valor formatado em reais.
 */
export function converterCentavosParaReais(centavos) {
  const reais = parseInt(centavos) / 100;
  return reais.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

/**
 * Converte um valor em reais para centavos.
 * @param {number} reais - O valor em reais a ser convertido.
 * @returns {number} - O valor em centavos.
 */
export function converterReaisParaCentavos(reais) {
  // Multiplica o valor em reais por 100 e arredonda para garantir que o resultado seja um número inteiro.
  return Math.round(reais * 100);
}

/**
 * Converte centavos em porcentagem
 * @param {number} centavos - O valor em centavos a ser convertido
 * @returns {number} - O valor convertido em porcentagem
 */
export function converterCentavosEmPorcentagem(centavos) {
  /* if (typeof centavos !== 'number' || centavos < 0) {
    throw new Error('Por favor, insira um valor válido em centavos.');
  } */
  return centavos / 100;
}

/**
 * Formata uma data no formato "YYYY-MM-DD" para "DD/MM/YYYY".
 * @param {string} data - A data a ser formatada.
 * @returns {string} - A data formatada.
 */
export function formatarDateSave(data) {
  return moment(new Date(data)).format("DD/MM/YYYY HH:mm:ss");
}

/**
 * Formata uma data para o formato "YYYY-MM-DD".
 * @param {string} data - A data a ser formatada.
 * @returns {string} - A data formatada.
 */
export function formatarDateAno(data) {
  return moment(new Date(data)).format("YYYY-MM-DD");
}

/**
 * Formata uma data para o formato "YYYY/MM/DD".
 * @param {string} data - A data a ser formatada.
 * @returns {string} - A data formatada.
 */
export function formatarDateAnoComparativo(data) {
  return moment(new Date(data)).format("YYYY/MM/DD");
}

/**
 * Formata uma data para o formato "MM/YY".
 * @param {string} data - A data a ser formatada.
 * @returns {string} - A data formatada.
 */
export function formatarDateMesAno(data) {
  return moment(new Date(data)).format("MM/YY");
}

/**
 * Formata uma data para o formato "DD/MM".
 * @param {string} data - A data a ser formatada.
 * @returns {string} - A data formatada.
 */
export function formatarDateDiaMes(data) {
  return moment(new Date(data)).format("DD/MM");
}

/**
 * Retorna um array de datas interpoladas entre duas datas.
 * @param {string} data1 - A data inicial.
 * @param {string} data2 - A data final.
 * @returns {string[]} - Array de datas formatadas.
 */
export function formatarDateMesInterpolacao(data1, data2) {
  let dates = [];
  let firstDate = moment(data1).startOf('day');
  let lastDate = moment(data2).startOf('day');

  dates.push(moment(firstDate.toDate()).format("DD/MM"));
  while (firstDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(moment(firstDate.clone().toDate()).format("DD/MM"));
  }
  dates.push(moment(lastDate.toDate()).format("DD/MM"));
  return dates;
}

/**
 * Aplica máscara a um CPF.
 * @param {string} cpf - O CPF a ser mascarado.
 * @returns {string} - O CPF mascarado.
 */
export function cpf_mask(cpf) {
  return cpf.replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
}

/**
 * Aplica máscara a um CNPJ.
 * @param {string} cnpj - O CNPJ a ser mascarado.
 * @returns {string} - O CNPJ mascarado.
 */
export function cnpj_mask(cnpj) {
  return cnpj.replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/, "$1.$2")
    .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    .replace(/\.(\d{3})(\d)/, ".$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2");
}

/**
 * Aplica máscara a um número de celular.
 * @param {string} num - O número de celular a ser mascarado.
 * @returns {string} - O número mascarado.
 */
export function cel_mask(num) {
  num = num.replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2");

  if (num.replace(/\D/g, "").length > 10) {
    num = num.replace(/(\d{5})(\d)/, "$1-$2");
  } else {
    num = num.replace(/(\d{4})(\d)/, "$1-$2");
  }
  return num;
}

/**
 * Testa a validade de um CNPJ.
 * @param {string} cnpj - O CNPJ a ser testado.
 * @returns {boolean} - Retorna verdadeiro se o CNPJ for válido, falso caso contrário.
 */
export function TestaCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj == '') return false;
  if (cnpj.length != 14) return false;

  if (["00000000000000", "11111111111111", "22222222222222", "33333333333333", "44444444444444", "55555555555555", "66666666666666", "77777777777777", "88888888888888", "99999999999999"].includes(cnpj)) return false;

  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(1)) return false;

  return true;
}

/**
 * Aplica máscara de decimal.
 * @param {number} num - O número a ser mascarado.
 * @returns {string} - O número mascarado.
 */
export function decimalMask(num) {
  return num.toString().replace(".", ",");
}

/**
 * Formata uma data para o formato "YYYY-MM-DD HH:mm".
 * @param {string} data - A data a ser formatada.
 * @returns {string} - A data formatada.
 */
export function formatarDateAnoHora(data) {
  return moment(new Date(data)).format("YYYY-MM-DD HH:mm");
}

/**
 * Formata uma data para o formato "YYYY-MM-DD HH:mm:ss".
 * @param {string} data - A data a ser formatada.
 * @returns {string} - A data formatada.
 */
export function formatarDateAnoHoraSegundo(data) {
  return moment(new Date(data)).format("YYYY-MM-DD HH:mm:ss");
}

