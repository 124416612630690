import axios from 'axios';
import { validateToken, getUsers, kyc, zipcode, createUser } from '../../../functions/functions';

export async function fetchContas(userId) {
  await validateToken();
  const users = await getUsers(1, 1000, userId);
  return users;
}

export async function fetchKYC(documento) {
  const response = await kyc(documento);
  return response;
}

export async function fetchCEP(cep) {
  const response = await zipcode(cep);
  return response;
}

export async function createAccount(data) {
  const response = await createUser(data);
  return response;
}

