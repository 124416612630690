import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import Comprovante from '../../components/pdf/pdfnovo';

const ComprovanteModal = ({ open, comprovante, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Comprovante</DialogTitle>
      <DialogContent>
        {comprovante && <Comprovante dados={comprovante} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ComprovanteModal;
